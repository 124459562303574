<template>
  <div class="modal contact-modal ready-modal">
    <div class="overlay" @click="$parent.closeReadyModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeReadyModal" src="./../images/close.svg" />
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields" v-if="!$parent.successMessage">
              <div class="title">{{ $t(`Are you sure?`) }}</div>
              <div class="price title">TOTAL: {{ modifiedPrice }} {{ $parent.internalCurrencyName }}</div>
              <button class="button" @click="buyProduct" :disabled="isLoading">
                <span v-if="!isLoading">{{ $t(`Buy`) }}</span>
                <span v-else class="loading-spinner"></span>
              </button>
              <transition name="fade">
                <div class="desc error-desc red" v-if="$parent.error">{{ $parent.error }}</div>
              </transition>
            </div>
            <transition name="fade">
              <div class="form-fields" v-if="$parent.successMessage">
                <div class="title error-desc green">{{ $parent.successMessage }}</div>
                <button class="button" @click="$parent.closeReadyModal">
                  <span>{{ $t(`Ok`) }}</span>
                </button>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReadyModal',
  props: [],
  components: {
  },
  data: function () {
    return {
      activePack: '',
      modifiedPrice: '',
      isLoading: false
    }
  },
  mounted() {
    if (this.$parent.readyProduct) {
      if (this.$parent.readyPromt) {
        this.modifiedPrice = this.$parent.readyProduct.price;
      } else if (this.$parent.readyPack) {
        this.modifiedPrice = this.$parent.activePack.price;
      }
    } else {
      if (this.$parent.readyPromt) {
        this.modifiedPrice = this.$parent.productPageItem.price;
      } else if (this.$parent.readyPack) {
        this.modifiedPrice = this.$parent.activePack.price;
      }
    }
    this.getActivePack();
  },
  methods: {
    getActivePack() {
      if (this.$parent.productPageItem.pack_id && this.$parent.productPageType != 'prompt') {
        console.log('pack page');
        this.$http.get(process.env.VUE_APP_API + 'packs/' + this.$parent.productPageItem.pack_id + '/info')
          .then((res) => {
            this.activePack = res.data.payload
            this.modifiedPrice = res.data.payload.price
          })
          .catch(() => {

          })
      } else {
        console.log('prompt page');
      }
    },
    buyProduct() {
      this.isLoading = true;
      if (this.$parent.productPageItem.pack_id && this.$parent.productPageType != 'prompt') {
        this.$parent.buyPack();
      } else {
        if (this.$parent.readyPack || (this.$parent.readyProduct && this.$parent.readyProduct.product_type === 'pack')) {
          this.$parent.buyPack();
        } else if (this.$parent.readyPromt) {
          this.$parent.buyProduct();
        }
      }
    }
  }
}
</script>

<style scoped>
.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255,255,255,0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
</style>