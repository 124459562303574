<template>
  <main class="main product-list-page product-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section">
        <div class="wrapper">
          <router-link to="/product-list" class="link hover-link desc">
            <img src="./../images/back.svg" class="img" />
            <span>{{ $t(`Back to Prompts`) }}</span>
          </router-link>
        </div>
      </section>
      <section class="section product-list-section">
        <img src="./../images/believe-peace.svg" class="img peace" />
        <div class="wrapper">
          <transition name="fade">
            <div class="product-preview" v-if="$parent.productPageItem">
              <div class="preview-container">
                <div class="preview-wrapper">
                  <div class="preview">
                    <img v-if="$parent.productPageItem.pack_id && $parent.productPageType != 'prompt'"
                      :src="$parent.imgDomain + activePack.image_url" class="img" />
                    <img v-if="!$parent.productPageItem.pack_id || $parent.productPageType == 'prompt'"
                      :src="$parent.imgDomain + $parent.productPageItem.image_url" class="img" />
                  </div>
                </div>
              </div>
              <div class="text">
                <div class="text-wrapper">
                  <div class="title" v-if="$parent.productPageItem.pack_id && $parent.productPageType != 'prompt'">
                    {{ activePack.title }}</div>
                  <div class="title" v-if="!$parent.productPageItem.pack_id || $parent.productPageType == 'prompt'">
                    {{ $parent.productPageItem.title }}</div>
                  <div class="tag">
                    <div class="desc">
                      <b>{{ $t(`Model:`) }}</b>
                    </div>
                    <div class="desc">{{ tag }}</div>
                  </div>
                  <div class="desc description">
                    <b>{{ $t(`Description:`) }}</b>
                    <div class="desc" v-if="$parent.productPageItem.pack_id && $parent.productPageType != 'prompt'"
                      v-html="activePack.description"></div>
                    <div class="desc" v-if="!$parent.productPageItem.pack_id || $parent.productPageType == 'prompt'"
                      v-html="$parent.productPageItem.description"></div>
                  </div>
                  <div class="row-price">
                    <div class="desc">
                      <b>{{ $t(`Price:`) }}</b>
                    </div>
                    <div v-if="$parent.productPageItem.pack_id && $parent.productPageType != 'prompt'" class="title">{{
              activePack.price }} <span class="currency">{{ currency }}</span></div>
                    <div v-if="!$parent.productPageItem.pack_id || $parent.productPageType == 'prompt'" class="title">{{
              $parent.productPageItem.price }} <span class="currency">{{ currency }}</span></div>
                    <button class="button green" @click="getPromt">
                      <span>{{ $t(`Get prompt`) }}</span>
                    </button>
                  </div>
                  <div class="spec-text">
                    <div class="desc" v-if="$parent.productPageItem.pack_id && $parent.productPageType != 'prompt'">
                      {{ $t(`After purchasing, you will gain access to the prompt file.`) }} <br />
                      <div>
                        {{ $t(`By purchasing this bundle, you agree to our `) }}
                        <a href="javascript:void(0);" @click="$parent.goToPage('terms')">{{ $t('terms of service')
                          }}</a>.
                      </div>
                    </div>
                    <div class="desc" v-if="!$parent.productPageItem.pack_id || $parent.productPageType == 'prompt'">
                      {{ $t(`After purchasing, you will gain access to the prompt file.`) }} <br />
                      <div>
                        {{ $t(`By purchasing this prompt, you agree to our `) }}
                        <a href="javascript:void(0);" @click="$parent.goToPage('terms')">{{ $t('terms of service')
                          }}</a>.
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div class="list products"
              v-if="($parent.productPageItem.pack_id && $parent.productPageType != 'prompt' && productList) || this.$route.params.type == 'pack'">
              <div class="item" v-for="(item, i) in productList" :key="i">
                <ProductCard :models="modelOptions" :tag="item.parent_cat_id" :item="item" :sliderIsClickble="true"
                  @goToProduct="goToProduct" :currency="currency" />
              </div>
              <div class="title no-products" v-if="!productList.length">{{ $t(`No products!`) }}</div>
            </div>
          </transition>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import ProductCard from '../components/ProductCard.vue';
import { mapGetters } from "vuex";
export default {
  name: 'ProductPage',
  props: ['currency', 'productPageItem'],
  components: {
    ProductCard
  },
  data: function () {
    return {
      tag: '',
      activePack: '',
      productList: [],
      modelOptions: [],
    }
  },
  watch: {
    currency: function () {
      const id = this.$route.params.id;
      this.$emit('getProductPageContent', id);
    },
    productPageItem: function (newVal) {
      this.getActivePack();
      this.$http.get(process.env.VUE_APP_API + 'categories')
        .then((res) => {
          this.models = res.data.payload.filter(item => item.parent_id === null);
          let newTag = this.models.find(item => item.id === newVal.parent_cat_id)
          newTag = newTag['title']
          this.tag = newTag;

        })
        .catch(() => {

        })
    },
  },
  created() {

  },

  mounted() {
    this.getActivePack();
    this.getModels();
  },
  methods: {
    getModels() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
        .then((res) => {
          const newCategories = res.data.payload.filter(item => item.parent_id === null);
          // newCategories.unshift({name: 'All'});
          this.modelOptions = newCategories.map(({
            id: id,
            title: text,
            url: url
          }) => ({
            id,
            text,
            url
          }));
          this.modelOptions.unshift({ id: 0, text: 'All', url: '' })
        })
        .catch(() => {

        })
    },
    getActivePack() {
      if (this.$route.params.type == 'pack') {
        if (!this.$parent.productPageItem) {
          this.$emit('getProductPageContent', this.$route.params.id, this.$route.params.type);
        }
        this.$http.get(process.env.VUE_APP_API + 'packs/' + this.$parent.productPageItem.id + '/info')
          .then((res) => {
            this.activePack = res.data.payload

            this.$http.get(process.env.VUE_APP_API + 'packs/' + this.activePack.id)
              .then((res) => {
                this.productList = res.data.payload;
              })
              .catch(() => {

              })
          })
          .catch(() => {

          })
      } else {
        if (!this.$parent.productPageItem) {
          this.$emit('getProductPageContent', this.$route.params.id, this.$route.params.type);
        }
      }
    },
    openReadyModal() {
      const product = this.$parent.productPageItem;
      const itemType = product && product.product_type === 'pack' ? 'pack' : 'prompt';
      this.$parent.openReadyModal(itemType, product);
    },
    getPromt() {
      if (this.isAuth) {
        this.openReadyModal();
      } else {
        this.$parent.openSignInModal();
      }
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    }
  },
  computed: {
    ...mapGetters('user', ['isAuth'])
  }
}
</script>