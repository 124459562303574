<template>
  <footer class="footer">
    <div class="footer-section">
      <div class="wrapper first">
        <div class="cicle">
          <img src="./../images/cicle1.svg" class="img" />
          <img src="./../images/cicle2.svg" class="img" />
          <img src="./../images/cicle3.svg" class="img" />
        </div>
        <div class="column first">
          <router-link to="/" class="logo flex">
            <img src="./../images/logo.svg" class="img" />
            <div class="title">Promptstorms</div>
          </router-link>
          <div class="desc">
            {{
              $t(`Promptstorms is Unleashing
            Creativity, Enhancing Conversations.
            Your Source for Diverse AI Prompts.`)
            }}
          </div>
        </div>
        <div class="column second">
          <div class="hover-link" @click="scrollToSection('page')">
            <img src="./../images/up.svg" class="img" />
          </div>
          <div class="nav first">
            <div class="nav__item">
              <router-link to="/" class="desc">{{ $t(`Home`) }}</router-link>
            </div>
            <div class="nav__item">
              <div @click="scrollToSection('features')" class="desc hover-link">
                {{ $t(`Features`) }}
              </div>
            </div>
            <div class="nav__item">
              <router-link to="/product-list" class="desc">{{
                $t(`Prompts`)
              }}</router-link>
            </div>
          </div>
          <div class="nav">
            <div class="nav__item">
              <div
                @click="scrollToSection('weBelieve')"
                class="desc hover-link"
              >
                {{ $t(`We believe`) }}
              </div>
            </div>
            <div class="nav__item">
              <div
                @click="scrollToSection('howItWorks')"
                class="desc hover-link"
              >
                {{ $t(`How it works`) }}
              </div>
            </div>
            <div class="nav__item">
              <router-link to="/contacts" class="desc">{{
                $t(`Contacts`)
              }}</router-link>
            </div>
          </div>
          <div class="text">
            <div class="item">
              <div class="title">{{ $t(`Company name`) }}</div>
              <div class="desc">{{ $parent.settings.company_name }}</div>
            </div>
            <div class="item">
              <div class="title">{{ $t(`Reg number`) }}</div>
              <div class="desc">{{ $parent.settings.company_reg_number }}</div>
            </div>
          </div>
          <div class="text">
            <div class="item">
              <div class="title">{{ $t(`Email`) }}</div>
              <div class="desc">{{ $parent.settings.support_email }}</div>
            </div>
            <div class="item">
              <div class="title">{{ $t(`Address`) }}</div>
              <div class="desc">{{ $parent.settings.company_address }}</div>
            </div>
          </div>
        </div>
        <div class="column third">
          <div class="bottom">
            <ul class="payment-methods-list">
              <li v-for="image in allFooterImages" :key="image" class="image-wrapper">
                <img :src="image" class="support-icon img" alt="Payment method" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="wrapper copyright">
        <div class="column">
          <ul class="nav">
            <li
              v-for="link in $parent.socialLinks"
              :key="link.image"
              class="nav__item nav__item-text-pages"
            >
              <a :href="link.url" class="desc" target="_blank">
                <img :src="link.image" :alt="link.url" class="img" />
              </a>
            </li>
          </ul>
        </div>
        <div class="column">
          <div class="desc">{{ $parent.settings.copyright }}</div>
        </div>
        <div class="column">
          <ul class="nav" v-if="$parent.textPageList.length">
            <li class="nav__item nav__item-text-pages"
              v-for="item in $parent.textPageList"
              :key="item.id"
            >
              <a class="desc" @click="$parent.goToPage(item.id)">
                {{ item.title }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: [],
  data: function () {
    return {};
  },
  computed: {
    paymentMethodsList() {
      if (!this.$parent.allPaymentMethods) return [];
      return this.$parent.allPaymentMethods;
    },
    allFooterImages() {
      if (this.$parent.settings && this.$parent.settings.footer_images && 
          this.$parent.settings.footer_images.length > 0) {
        return this.$parent.settings.footer_images;
      }
      
      return [];
    },
  },
  methods: {
    scrollToSection(section) {
      this.$emit("scrollToSection", section);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.footer .payment-methods-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .image-wrapper {
    /* background-color: white; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    max-width: 70px;
  }

  .support-icon.img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }
}
</style>
