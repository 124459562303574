<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section" id="hero">
        <div class="wrapper">
          <div class="left">
            <div class="title big">
              Promptstorms -<br />{{ $t(`AI prompt bundle digital emporium`) }}</div>
            <div class="desc">{{ $t(`AI Prompts for all your creative needs in one place.`) }}</div>
            <router-link to="/product-list">

              <div class="ticker-wrap">
                <div class="ticker">
                  <span class="item-collection-1">
                    <span class="item">
                      {{ $t(`Browse prompts`) }}
                      <img src="./../images/tick-arrow.svg" class="img" />
                    </span>
                    <span class="item">
                      {{ $t(`Browse prompts`) }}
                      <img src="./../images/tick-arrow.svg" class="img" />
                    </span>
                    <span class="item">
                      {{ $t(`Browse prompts`) }}
                      <img src="./../images/tick-arrow.svg" class="img" />
                    </span>
                    <span class="item">
                      {{ $t(`Browse prompts`) }}
                      <img src="./../images/tick-arrow.svg" class="img" />
                    </span>
                  </span>
                  <span class="item-collection-2">
                    <span class="item">
                      {{ $t(`Browse prompts`) }}
                      <img src="./../images/tick-arrow.svg" class="img" />
                    </span>
                    <span class="item">
                      {{ $t(`Browse prompts`) }}
                      <img src="./../images/tick-arrow.svg" class="img" />
                    </span>
                    <span class="item">
                      {{ $t(`Browse prompts`) }}
                      <img src="./../images/tick-arrow.svg" class="img" />
                    </span>
                    <span class="item">
                      {{ $t(`Browse prompts`) }}
                      <img src="./../images/tick-arrow.svg" class="img" />
                    </span>
                  </span>
                </div>
              </div>

            </router-link>
          </div>
          <div class="right">
            <img src="./../images/hero.png" class="img" />
          </div>
        </div>
        <img src="./../images/hero-peace.svg" class="img peace" />
      </div>
      
      <RandomCasesSlider />

      <div class="section arrivals-section">
        <div class="top">
          <div class="title">{{ $t(`Trending prompts`) }}</div>
        </div>
        <div class="wrapper">
          <SlickItem v-if="$parent.tranding && $parent.tranding.length" :isPack="false" :list="$parent.tranding"
            :tag="'Stable Diffusion'" @goToProduct="goToProduct" :isAuth="isAuth" :currency="currency"
            @openReadyModal="openReadyModal" @openSignInModal="$parent.openSignInModal" />
        </div>
      </div>

      <div class="section features-section" id="features">
        <div class="wrapper">
          <div class="title big">{{ $t(`Features`) }}</div>
          <div class="list flex">
            <div class="column">
              <div class="item">
                <div class="title big">01</div>
                <div class="desc big">{{ $t(`Save time and effort`) }}</div>
                <div class="desc">{{ $t(`Promptstorms makes it easy to find high-quality AI prompts for all your
                  creative needs.`) }}</div>
              </div>
            </div>
            <div class="column">
              <div class="item">
                <div class="title big">02</div>
                <div class="desc big">{{ $t(`Buy once, use forever`) }}</div>
                <div class="desc">{{ $t(`Promptstorms offers lifetime access and lifetime updates to all purchased AI
                  tips.`) }}</div>
              </div>
              <div class="item">
                <div class="title big">03</div>
                <div class="desc big">{{ $t(`Improve your AI skills`) }}</div>
                <div class="desc">{{ $t(`Promptstorms is a great way to learn more about AI and how to use them
                  effectively.`) }}</div>
              </div>
            </div>
            <div class="column">
              <div class="item feaatures-item">
                <img src="./../images/feaatures.png" class="img feaatures" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="how-it-works" id="howItWorks">
        <div class="section">
          <div class="wrapper">
            <div class="title big">{{ $t(`How it works?`) }}</div>
            <div class="list">
              <div class="item">
                <img src="./../images/line1.svg" class="img" />
                <div class="desc big">1.</div>
                <div class="desc">{{ $t(`Browse prompts filtered by category, topic and style. You can also use keywords
                  to search for specific prompts.`) }}</div>
                <img src="./../images/line-mob1.svg" class="img mob" />
              </div>
              <div class="item">
                <img src="./../images/line3.svg" class="img" />
                <div class="desc big">3.</div>
                <div class="desc">{{ $t(`When you find the right prompt, click the "Buy" button.`) }}</div>
                <img src="./../images/line-mob2.svg" class="img mob" />
              </div>
              <div class="item">
                <img src="./../images/line2.svg" class="img" />
                <div class="desc big">2.</div>
                <div class="desc">{{ $t(`Before buying a prompt, read the description carefully to make sure that’s what
                  you're looking for.`) }}</div>
                <img src="./../images/line-mob3.svg" class="img mob" />
              </div>
              <div class="item">
                <div class="desc big">4.</div>
                <div class="desc">{{ $t(`Once the purchase is complete, you can download the prompt as a text file or
                  copy and paste it directly into your favourite AI tool.`) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section pack-section">
        <div class="wrapper">
          <div class="title medium">{{ $t(`Choose the prompts that resonate with you and take your creativity to new
            heights.`) }}</div>
          <SlickItem v-if="$parent.packs && $parent.packs.length" @openSignInModal="$parent.openSignInModal"
            :isAuth="isAuth" :activePack="$parent.activePack" @openReadyModal="openReadyModal" :list="$parent.packs"
            :tag="$parent.activePack.title" @goToProduct="goToProduct" :currency="currency" :isPack="true" />
        </div>
      </div>
      <div class="section we-believe-section" id="weBelieve">
        <div class="wrapper">
          <div class="title big">{{ $t(`We believe!`) }}</div>
          <div class="desc">{{ $t(`At Promptstorms we believe in the power of human creativity and the potential of
            artificial intelligence to unlock new realms of imagination. We're driven by a single goal - to bridge the
            gap between human ingenuity and AI capabilities.`) }}</div>
          <img src="./../images/we-believe.png" class="img" />
        </div>
        <img src="./../images/believe-peace.svg" class="img peace" />
      </div>
    </div>
  </main>
</template>
<script>
import SlickItem from '../components/SlickItem.vue';
import RandomCasesSlider from '../components/RandomCasesSlider.vue';
import { mapGetters } from "vuex";


export default {
  name: 'Home',
  props: ['currency'],
  components: {
    SlickItem,
    RandomCasesSlider
  },
  data: function () {
    return {
      imgDomain: '',
    }
  },
  watch: {
    currency: function () {
      this.$parent.getPacks();
      this.$parent.getTranding();
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    setChatGptModel() {
      let model = this.$parent.models.find((obj) => obj.id === 8)
      this.$emit('setActiveModel', model);
    },
    setDalle2Model() {
      let model = this.$parent.models.find((obj) => obj.id === 9)
      this.$emit('setActiveModel', model);
    },
    setStableDiffusionModel() {
      let model = this.$parent.models.find((obj) => obj.id === 10)
      this.$emit('setActiveModel', model);
    },
    openReadyModal(item, product) {
      this.$emit('openReadyModal', item, product);
    }
  },
  computed: {
    ...mapGetters('user', ['isAuth'])
  }
}
</script>