<template>
  <main class="main product-list-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section">
        <div class="wrapper">
          <div class="title big">{{ $t(`Prompts`) }}</div>
        </div>
      </section>
      <section class="section product-list-section">
        <img src="./../images/believe-peace.svg" class="img peace"/>
        <div class="wrapper">
          <div class="top">
            <div class="search">
              <input type="text" :placeholder="$t('Type here to search')" v-model="search" v-on:keyup.enter="getProducts"/>
              <button class="button green" @click="getProducts">{{ $t(`Search`) }}</button>
            </div>
            <div class="filters">
              <div class="filters-wrapper">
                <div class="left">
                  <div class="select-container" v-if="sortOptions.length">
                    <Select2 class="select2" v-model="sort" 
                    :options="sortOptions" 
                    :placeholder="$t('Sort')"
                    @select="selectSort($event)"
                    />
                  </div>
                  <div class="select-container" v-if="modelOptions && modelOptions.length > 1">
                    <Select2 class="select2 single" v-model="activeModel" 
                    :options="modelOptions" 
                    :placeholder="$t('Model')"
                    @select="onSelectModel($event)"
                    />
                  </div>
                  <div class="select-container" v-if="categoryOptions.length">
                    <Select2 class="select2 single" v-model="activeCategory" 
                    :options="categoryOptions" 
                    :placeholder="$t('Category')"
                    @select="onSelectCategory($event)"
                    />
                  </div>
                </div>
                <div class="select-container select-container-reset">
                  <button class="button" @click="getProducts">{{ $t(`Apply`) }}</button>
                </div>
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="list products" v-if="productList">
              <div class="item" v-for="(item, i) in productList" :key="i">
                <ProductCard :models="modelOptions" :tag="item.parent_cat_id" :item="item" :sliderIsClickble="true" @goToProduct="goToProduct" @openReadyModal="openReadyModal" @openSignInModal="openSignInModal" :isAuth="isAuth" :currency="currency"/>
              </div>
              <div class="title no-products" v-if="!productList.length">{{ $t(`No products!`) }}</div>
            </div>
          </transition>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import Select2 from 'vue3-select2-component';
import ProductCard from '../components/ProductCard.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ProductList',
  props: ['currency'],
  components: {
    Select2,
    ProductCard
  },
  data: function() {
    return {
      imgDomain: '',
      sort: '',
      categoryOptions: [],
      modelOptions: [],
      activeModel: '',
      activeCategory: '',
      productList: [],
      search: '',
      sortOptions: [
        {
          id: 'desc',
          text: 'Highest price first'
        }, 
        {
          id: 'asc',
          text:'Lowest price first'
        }
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['isAuth']),
  },
  watch: {
    currency: function() {
      this.getProducts();
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getProducts();
    this.getModels();
    this.getCategories();
  },
  methods: {
    openReadyModal(item, product) {
      this.$emit('openReadyModal', item, product);
    },
    openSignInModal() {
      this.$emit('openSignInModal');
    },
    getModels() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        const newCategories = res.data.payload.filter(item => item.parent_id === null);
        // newCategories.unshift({name: 'All'});
        this.modelOptions = newCategories.map(({
          id: id,
          title: text,
          url: url
        }) => ({
          id,
          text,
          url
        }));
        this.modelOptions.unshift({id: 0, text: 'All', url: ''})
      })
      .catch(() => {
         
      })
    },
    getCategories() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        const newCategories = res.data.payload.filter(item => item.parent_id !== null);
        this.categoryOptions = newCategories.map(({
          id: id,
          title: text,
          url: url
        }) => ({
          id,
          text,
          url
        }));
        const filteredArray = this.removeIdenticalObjects(this.categoryOptions);
        this.categoryOptions = filteredArray;

        this.categoryOptions.unshift({id: 0, text: 'All', url: ''})
      })
      .catch(() => {
         
      })
    },
    removeIdenticalObjects(arr) {
      return arr.filter((item, index) => {
        const firstIndex = arr.findIndex((el) => el.text === item.text);
        return index === firstIndex;
      });
    },
    getProducts() {
      let addCategory = '';
      if (this.category != 'All') {
        addCategory = 'category_id=' + this.activeCategory
      } else {
        addCategory = '';
      }

      let addModel = '';
      if (this.activeModel != 'All') {
        addModel = '&model_id=' + this.activeModel
      } else {
        addModel = '';
      }

      let addSearch = '';
      if (this.search) {
        addSearch = '&search=' + this.search
      } else {
        addSearch = '';
      }

    

      let addSort = '&sort=' + this.sort
      
      this.$http.get(process.env.VUE_APP_API + 'products/catalog?' + addCategory + addModel + addSort + addSearch)
      .then((res) => {
        this.productList = res.data.payload
        this.totalProducts = res.data.meta.total
      })
      .catch(() => {
       
      })
    },
    onSelectCategory(event) {
      this.activeCategory = event.id;
    },
    onSelectModel(event) {
      this.activeModel = event.id;
    },
    selectSort(event) {
      this.sort = event.id;
    },
    resetFilters() {
      this.$parent.setAllModels();
      this.$parent.clearSearch();
      this.$parent.openProducts();
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    }
  }
}
</script>